import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import sortBy from "lodash.sortby";
import { PadSection } from "components/Section/Section";
import { fontstack } from "utils/fontstack";
import { media } from "utils/mediaQuery";

const Wrapper = styled(PadSection)`
  position: relative;
  padding-top: 5vw;
  padding-bottom: 10vw;
`;


const MobileFilterHead = styled.div`
  border-top: 1.5px solid white;
  padding: 12px 0 0;
  ${fontstack.default}
  font-size: var(--font-size-base);
  font-weight: 400;
  color: white;


  ${media.medium` 
    display: none;
  `}
`

const FilterGrid = styled(Grid)`
  overflow: hidden;
  max-height: ${(props) => props.open ? '1000px' : 0};
  transition: max-height .2s ease;
  ${media.medium` 
    max-height: initial;
  `}
`

const Tags = styled.div`
  grid-column: 1 / span 6;
  display: flex;
  flex-wrap: wrap;
  border-top: 1.5px solid #434343;
  padding: 20px 0 0;
  margin: 46px 0 0;

  ${media.medium` 
    grid-column: 2 / span 10;
    border-top: none;
    padding: 0;
    margin: 0;
  `}
`;

const Tag = styled.div`
  position: relative;
  ${fontstack.default}
  font-size: var(--font-size-md);
  line-height: 1.083;
  font-weight: 400;
  color: ${(props) => props.active ? "white" : "#434343"};
  margin: 0 16px 0 0;
  cursor: pointer;
  transition: color .2s ease;

  ${media.medium` 
    margin: 0 30px 0 0;

    &:after {
      content: ${(props) => props.sub ? `"${props.sub}"` : ''};
      display: inline-block;
      font-size: 17px;
      line-height: 20px;
      font-weight: 400;
      height: 100%;
      position: absolute;
      top: 2px;
    }
  `}

  &:hover {
    color: white;
  }
`;

const CategoryFilter = ({total, filteredTotal, tags,setFilter, filter}) => {
  const [ IsOpen, setIsOpen ] = React.useState(false);
  const sortedTags = sortBy(tags, "label");

  const onClick = (label) => {
    setFilter(label);
    setIsOpen(false);
  }

  return (
    <Wrapper>
      <MobileFilterHead onClick={()=>setIsOpen(!IsOpen)}>
        Filter ({filteredTotal})
      </MobileFilterHead>
      <FilterGrid open={IsOpen}>
        <Tags>
          <Tag sub={total} onClick={() => { onClick(null) }} active={!filter || false}>All</Tag>
          { sortedTags && sortedTags.map((tag) => {
            return <Tag key={tag.value.toLowerCase()} onClick={() => {
              onClick(tag.label);
            }} active={filter === tag.label || false}>{tag.label}</Tag>
          })}
        </Tags>
      </FilterGrid>
    </Wrapper>
  )
}

export default CategoryFilter;
