import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { PadSection } from "components/Section/Section";
import { ScrollTrigger } from "gsap/all";
import { fontstack } from "utils/fontstack";
import { media } from "utils/mediaQuery";
import WorkVideoSummary from "components/WorkVideoSummary/WorkVideoSummary";

const Wrapper = styled(PadSection)`
  position: relative;
`

const Col = styled.div`
  display: none;
  margin: ${(props) => props.shifted ? "21vw 0 0" : "0"};
  & > a {
    margin: 0 0 18vw;
    display: block;
  }

  ${media.medium` 
    display: block;
  `}
`

const MobileCol = styled.div`
  & > a {
    margin: 0 0 80px;
    display: block;
  }

  ${media.medium` 
    display: none;
  `}
`

const Inner = styled.div`
  position: relative;
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: repeat(1, 1fr);

  ${media.medium` 
    grid-column-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  `}
`

const VideoGrid = ({nodes}) => {

  React.useEffect(() => {

    ScrollTrigger.refresh();
  }, [nodes])

  const allProjects = nodes;
  const oddProjects = nodes.filter((n,i) => i % 2 == 1);
  const evenProjects = nodes.filter((n,i) => i % 2 == 0);


  return (
    <Wrapper>
      <Inner>
        <MobileCol>
          { allProjects && allProjects.map((project,i) => {
            return <Link key={project.slug.current} to={`/project/${project.slug.current}`} data-speed="0.95"><WorkVideoSummary node={project} key={`mobile-work-${i}`}/></Link>
          })}
        </MobileCol>
        <Col shifted>
          { oddProjects && oddProjects.map((project,i) => {
            return <Link key={project.slug.current} to={`/project/${project.slug.current}`} data-speed="0.95"><WorkVideoSummary node={project} key={`work-odd-${i}`}/></Link>
          })}
        </Col>
        <Col>
          { evenProjects && evenProjects.map((project,i) => {
            return <Link key={project.slug.current} to={`/project/${project.slug.current}`} data-speed="1.05"><WorkVideoSummary node={project} key={`work-even-${i}`}/></Link>
          })}
        </Col>
      </Inner>
    </Wrapper>
  )
}

export default VideoGrid;
