import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { media } from "utils/mediaQuery";
import { fontstack } from "utils/fontstack";

const Outer = styled.div`
  position: relative;
  text-align: center;
  padding: 10vw 0 0;
`;

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin: 80px 0 0;
  display: inline-block;

  ${media.medium` 
    width: 400px;
    margin: 100px 0 0;
  `}
`;

const MenuBackground = styled.div` 
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.2);
  z-index: 0;
  border-radius: 10px;
`;

const CursorWrapper = styled.div`
  position: absolute;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  z-index: 0;
  top: -2px;
  left: -2px;
  opacity: 0;
  transition: opacity .4s ease;

`
const GradientCursor = styled.div`  
  position: absolute;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  z-index: 0;
  top: -2px;
  left: -2px;
  opacity: 0;
  transition: opacity .4s ease;
  ${({gradient}) => gradient && `
    background: linear-gradient(135deg, ${gradient[0]}, ${gradient[1]});
  `}

  filter: blur(10px);
  border-radius: 8px;
  will-change: opacity;
`;

const CategoryMenu = styled.ul` 
  position: relative;
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  margin: 15px 0;
`;

const CategoryLabel = styled.div`
  position: relative;
  z-index: 1;
  transition: all .2s ease;
`

const CategoryItem = styled.li`
  position: relative;
  ${fontstack.default}
  font-size: var(--font-size-base);
  font-weight: 400;
  color: white;
  padding: 0 15px;
  cursor: pointer;
  pointer-events: auto;

  &:hover > ${CategoryLabel}{
    filter: drop-shadow(0px 0px 2px white);
    opacity: 1;
  }

  &:hover > ${GradientCursor} {
    opacity: 1;
  }

  & > ${CategoryLabel} {
    filter: ${(props) => props.active ? "drop-shadow(0px 0px 2px white)" : "none"};
    opacity: ${(props) => props.active ? 1 : 0.2};
  }

  & > ${GradientCursor} {
    opacity: ${(props) => props.active ? 1 : 0};
  }
`;



const WorkCatMenu = ({activeCat}) => {
  return (
    <Outer>
      <Wrapper>
        <CategoryMenu>
          <CategoryItem active={activeCat === "/" || false}><CategoryLabel><Link to="/work">All</Link></CategoryLabel></CategoryItem>
          <CategoryItem active={activeCat === "biotech" || false}>
            <CategoryLabel><Link to="/work/biotech">BioTech</Link></CategoryLabel>
            <GradientCursor gradient={['#079F76', '#3569F8']} />
          </CategoryItem>
          <CategoryItem active={activeCat === "tech" || false}>
            <CategoryLabel><Link to="/work/tech">Tech</Link></CategoryLabel>
            <GradientCursor gradient={['#7D0DFF', '#9FCFF2']} />
          </CategoryItem>
          <CategoryItem active={activeCat === "create" || false}>
            <CategoryLabel><Link to="/work/create">Create</Link></CategoryLabel>
            <GradientCursor gradient={['#FA4B45', '#C49CD9']} />
          </CategoryItem>
        </CategoryMenu>
        <MenuBackground />
      </Wrapper>
    </Outer>
  )
}

export default WorkCatMenu;
